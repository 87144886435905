/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {useEffect, useState} from 'react';
import VendorTable from '../../../components/table/VendorTable';
import {AdminServices} from '../../../redux/services/admin/admin';
import {useAdmin} from '../../../app/hooks';
import {IVendor} from '../../../components/interfaces/vendors';
import {axiosError} from '../../../components/Helpers';
import Pagination from '../../../components/ui/Pagination';
import {toast} from 'react-toastify';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {addMonths} from 'date-fns';
import DateRangePicker from '../../../components/forms/inputs/selectInput/DateRangePicker';

const Vendors = ({show}: any) => {
  localStorage.setItem('currentPath', '/admin/users');

  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [vendors, setVendors] = useState<IVendor[]>([]);
  // Query
  const [query, setQuery] = useState('');
  const keys = ['firstName', 'email', 'phone'];
  const search = (data: any) => {
    return data.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };

  // Get Vendors

  // Filter Start and End date
  const [startDate, setStartDate] = useState(new Date('2024-06-01'));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 6));

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  // Get Vendors
  useEffect(() => {
    getVendors();
  }, [startDate, endDate]);

  const getVendors = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getVendorsWithDateRange(
        token,
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0]
      );
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response.data?.data.vendors;
        setVendors(res.rows);
        // console.log(res.rows);
        setIsLoading(false);
        // console.log('profile', profile);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  // Delete Vendor
  const onDelete = async (id: any, reason: string) => {
    if (!reason) {
      toast.error('Please Select a reason for deactivation!');
      return;
    }
    const data = {
      isActive: false,
      deactivationReason: reason,
    };
    setIsActionLoading(true);
    try {
      const response = await AdminServices.deleteVendor(token, id, data);
      // console.log('Deactivate', response.data);
      if (response.data.status === 'success') {
        toast.success(response.data.data.message);
        setIsActionLoading(false);
        // closing the modal and trigger data reload
        await getVendors();
      } else if (
        (response?.data.status === 'failed' &&
          response?.data.message === 'Invalid token or token expired') ||
        response?.data.message === 'Authorization denied! Token expired'
      ) {
        localStorage.clear();
        window.location.reload();
        setIsActionLoading(false);
      } else if (
        response?.data.status === 'failed' &&
        response?.data.message === 'ERR no such key'
      ) {
        console.log(response?.data.message);
        setIsActionLoading(false);
      } else {
        toast.error(response?.data.message || 'Connection Error');
        setIsActionLoading(false);
      }
      // setIsLoading(false);
    } catch (error: any) {
      // axiosError(error.response);
      console.log(error);
      setIsActionLoading(false);
    }
  };
  // ACtivate Vendor
  const onActivate = async (id: any) => {
    const data = {
      isActive: true,
    };
    setIsActionLoading(true);
    try {
      const response = await AdminServices.deleteVendor(token, id, data);
      // console.log('Activate', response.data);
      if (response.data.status === 'success') {
        toast.success(response.data.data.message);
        setIsActionLoading(false);
        // closing the modal and trigger data reload
        await getVendors();
      } else if (
        (response?.data.status === 'failed' &&
          response?.data.message === 'Invalid token or token expired') ||
        response?.data.message === 'Authorization denied! Token expired'
      ) {
        localStorage.clear();
        window.location.reload();
        setIsActionLoading(false);
      } else if (
        response?.data.status === 'failed' &&
        response?.data.message === 'ERR no such key'
      ) {
        console.log(response?.data.message);
        setIsActionLoading(false);
      } else {
        toast.error(response?.data.message || 'Connection Error');
        setIsActionLoading(false);
      }
      // setIsLoading(false);
    } catch (error: any) {
      // axiosError(error.response);
      console.log(error);
      setIsActionLoading(false);
    }
  };

  // Sort --------------------
  const [openSort, setOpenSort] = useState(false);

  const [sortingOption, setSortingOption] =
    useState<keyof IVendor>('firstName');
  const [isAscending, setIsAscending] = useState(true);

  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const sortedData = [...vendors].sort((a, b) => {
    // Use the selected sorting option
    const sortOrder = isAscending ? 1 : -1;
    const aValue = a[sortingOption];
    const bValue = b[sortingOption];

    // Compare values based on the sorting order
    if (aValue < bValue) return -1 * sortOrder;
    if (aValue > bValue) return 1 * sortOrder;
    return 0;
  });

  // Pagination
  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const token = useAdmin().adminToken;

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = sortedData.slice(firstPageIndex, lastPageIndex);

  // console.log(currentTableData);
  const totalPages = Math.ceil(vendors.length / PageSize);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  //  Download selected rows to csv
  const downloadSelectedRows = () => {
    // Filter the data based on selectedRows
    const selectedData = vendors;

    // Create CSV headers
    const csvHeaders = ['First Name', 'Last Name', 'Email', 'Phone', 'Status'];

    // Convert selectedData to CSV format
    const csvData: string[] = [];
    csvData.push(csvHeaders.join(',')); // Add headers to the CSV data
    selectedData.forEach((row: any) => {
      csvData.push(
        `${row.firstName},${row.lastName},${row.email},${row.phone}, ${row.isActive}`
      );
    });

    // Create a CSV string
    const csvString = csvData.join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvString], {type: 'text/csv'});

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'vendors.csv');

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Vendors</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>VENDORS</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='flex flex-row items-center justify-end gap-6 pt-4'>
          <button
            onClick={downloadSelectedRows}
            className='bg-primary text-white px-3 py-2 text-center transition-hover rounded-sm'>
            Download CSV
          </button>
          {/* Sort */}
          <div
            onClick={() => setOpenSort(!openSort)}
            className='flex flex-row items-center gap-4 cursor-pointer'>
            <div className='font-poppinsMedium'>Sort by:</div>
            <div className='relative'>
              <div className='flex flex-row items-center gap-10'>
                <p className='text-[#868FE1] text-xs font-poppinsMedium'>
                  {sortingOption === 'isActive'
                    ? 'Status'
                    : sortingOption.charAt(0).toUpperCase() +
                      sortingOption.slice(1)}
                </p>
                <BiChevronDown size={20} color='#868FE1' />
              </div>
              {/* Sort DropDown */}
              {openSort && (
                <div
                  onClick={() => setOpenSort(false)}
                  className='bg-white w-full px-2 py-2 flex flex-col items-start absolute rounded-md shadow-md top-8 z-50 text-xs gap-2'>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('isActive')}>
                    Status
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('firstName')}>
                    First Name
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('lastName')}>
                    Last Name
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('email')}>
                    Email
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Date Filter */}

          <DateRangePicker
            endDate={endDate}
            onEndDateChange={handleEndDateChange}
            onStartDateChange={handleStartDateChange}
            startDate={startDate}
          />
        </div>
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <VendorTable
                vendors={search(currentTableData)}
                onDeactivate={onDelete}
                onActivate={onActivate}
                isActionLoading={isActionLoading}
              />
            )}
          </div>
        </div>

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default Vendors;
