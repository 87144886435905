/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {useEffect, useState} from 'react';
import ProductMain from './productMain';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {useVendor} from '../../../app/hooks';
import {axiosError} from '../../../components/Helpers';
import {COLORS} from '../../../assets/color';
import Button from '../../../components/ui/Button';
import VendorProductTable from './VendorProductTable';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import Pagination from '../../../components/ui/Pagination';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {useNavigate} from 'react-router-dom';
import {BiSearch} from 'react-icons/bi';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

const Products = ({show}: any) => {
  const [products, setProducts] = useState<any>([]);
  const [profile, setProfile] = useState<any>([]);
  const token = useVendor().token;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  localStorage.setItem('currentPath', '/vendor/products');

  // Modal integration
  const [showUpload, setShowUpload] = useState(false);
  // Query
  const [query, setQuery] = useState('');
  const keys = ['name'];
  const search = (data: any) => {
    return data?.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };
  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = products?.slice(firstPageIndex, lastPageIndex);

  // console.log(currentTableData);
  const totalPages = Math.ceil(products?.length / PageSize);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  // Get Profile
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setIsLoading(true);
    try {
      const response = await VendorServices.getProfile(token);
      // console.log('profile', response.data.data);
      if (response.data.status === 'success') {
        // toast.success(response.data.data.message);
        const res = response.data.data.vendor;
        // console.log(res);
        setProfile(res);
        setProducts(res?.products);
        localStorage.setItem('#prds', JSON.stringify(res?.products));
        setIsLoading(false);
      }
    } catch (error: any) {
      console.log(error.response);
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  // Upload Product

  const businessName = useVendor().vendor.businessName;
  const vendorEmail = useVendor().vendor.email;

  const handleSubmit = async (
    formDataAddImg: any,
    name: string,
    description: string,
    subCategory: string,
    quantity: string,
    discount: string,
    superCategory: string,
    category: string,
    price: string,
    location: string,
    brand: string,
    warranty: string,
    minimumStock: string,
    dimension: string,
    volume: string,
    weight: string,
    count: string,
    commission: string,
    color: string,
    size: string,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      setIsUploading(true);

      if (!name || !description || !subCategory) {
        toast.error('All fields are required!');
        setIsUploading(false);
      }
      // Loading

      // Submit additional images and get the URLs
      const images = formDataAddImg;

      if (!images) {
        console.error('No images selected for upload.');
        return;
      }

      const data = new FormData();
      for (let i = 0; i < images.length; i++) {
        data.append(`images`, images[i]);
      }

      const imageRes = await VendorServices.multiProductImageUpload(
        token,
        data,
        businessName
      );

      if (imageRes.data.status === 'success') {
        // const urls = imageRes.data.data.urls;
        // Log the URLs to ensure they are correct
        // console.log('Image URLs:', urls);
        // toast.success(imageRes.data.data.message);
      } else {
        toast.error(imageRes.data.data.message);
        setIsUploading(false);
        return; // Exit the function if image upload fails
      }
      try {
        const productResData = {
          name: name,
          description: description,
          subCategory: subCategory,
          quantity: quantity,
          discount: discount,
          superCategory: superCategory,
          category: category,
          price: price,
          location: location,
          brand: brand,
          warranty: warranty,
          businessName,
          vendorMail: vendorEmail,
          minimumStock: minimumStock,
          additionalImg: imageRes.data.data.urls,
          dimension: dimension,
          volume: volume,
          weight: weight,
          count: count,
          commission: Number(commission),
          color: color,
          size: size,
        };
        // Proceed to upload the product with the updated state
        const productRes = await VendorServices.uploadProduct(
          token,
          productResData
        );

        setIsUploading(false);

        if (productRes.data.status === 'success') {
          toast.success(
            productRes.data.data.message || 'Product successfully Uploaded.'
          );
          setShowUpload(false);
          getProfile();
        }
      } catch (error: any) {
        axiosError(error.response);
        // console.log(error);
        setIsUploading(false);
      }
    } catch (error: any) {
      console.log(error);
      axiosError(error.response);
      setIsUploading(false);
    }
  };
  //  Download selected rows to csv
  const downloadSelectedRows = () => {
    // Filter the data based on selectedRows
    const selectedData = products;

    // Create CSV headers
    const csvHeaders = ['Name', 'stock', 'Price NGN', 'Status'];

    // Convert selectedData to CSV format
    const csvData: string[] = [];
    csvData.push(csvHeaders.join(',')); // Add headers to the CSV data
    selectedData.forEach((row: any) => {
      csvData.push(`${row.name},${row.quantity},${row.price},${row.status}`);
    });

    // Create a CSV string
    const csvString = csvData.join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvString], {type: 'text/csv'});

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'products.csv');

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <>
      <AppContainer>
        <div className='xl:w-[90%] md:w-[95%] w-full pt-4'>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Products</title>
          </Helmet>
          <ProductBar>
            <Title>
              <h2>PRODUCTS</h2>
            </Title>
            <div className='flex flex-row items-center gap-4 justify-end '>
              {/* Search */}
              <div className=' flex flex-row items-center w-full justify-between'>
                {/* Search Input  */}
                <div className='bg-[#F9FBFF] px-4 flex items-center sm:w-56 w-36 rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                  <div className='input w-full py-[4px] '>
                    <input
                      type='search'
                      placeholder='Search'
                      className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                      onChange={(e: any) => setQuery(e.target.value)}
                    />
                  </div>
                  <div className='icon text-gray-200'>
                    <BiSearch size={22} color={COLORS.primary} />
                  </div>
                </div>
              </div>
              {/* Notification */}
              <div className='sm:block hidden'>
                <NotificationIcon onClick={() => ''} />
              </div>
              {/* Profile Image */}
              <div className='sm:block hidden'>
                <ProfileImage
                  onClick={() => navigate('../vendor/profile')}
                  profileImage={profile?.vendorImg}
                />
              </div>
            </div>
          </ProductBar>
          {showUpload && (
            <ProductMain
              setIsOpen={setShowUpload}
              handleSubmit={handleSubmit}
            />
          )}
          <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
            <div className=''>
              {/* New Table */}
              {isLoading ? (
                <TableDataSkeleton />
              ) : (
                <div>
                  <div className=' flex flex-row items-center w-full gap-4 justify-between pt-4 pl-2'>
                    {/* Button */}
                    <>
                      {profile.isUpdated === true ? (
                        <div className=''>
                          <Button
                            label={'Upload Product'}
                            width='small'
                            bgColor={COLORS.primary}
                            borderCOlor={COLORS.primary}
                            textCOlor='white'
                            onClick={() => setShowUpload(true)}
                          />
                        </div>
                      ) : (
                        <p className='text-base'>
                          {' '}
                          Please Note:{' '}
                          <Link
                            to={'../vendor/profile'}
                            className='font-semibold pl-4 text-orange-400 text-sm hover:underline'>
                            {' '}
                            You must complete your profile to enable product
                            upload.
                          </Link>
                        </p>
                      )}
                    </>
                    <button
                      onClick={downloadSelectedRows}
                      className='bg-primary text-white px-3 py-2 text-center transition-hover rounded-sm'>
                      Download CSV
                    </button>
                  </div>
                  <VendorProductTable
                    products={search(currentTableData)}
                    setProducts={setProducts}
                  />
                </div>
              )}
              {/* Pagination */}
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </div>
          </div>
        </div>
      </AppContainer>
    </>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;

export default Products;
