import styles from '../../assets/styles/modals/ConfirmModal.module.css';
import CustomDropDown from '../forms/CustomDropDown';
interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAction?: () => void;
  label?: string;
  bgColor?: string;
  activeLabel?: string;
  options: any;
  handleDropdownChange: any;
}
const ConfirmDeactivateUser = ({
  setIsOpen,
  onAction: deleHandler,
  label,
  options,
  handleDropdownChange,
}: IModal) => {
  // const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.confirmDeleteBG}
        onClick={() => {
          // navigate(-1);
          setIsOpen(false);
        }}
      />
      <div className={styles.centered}>
        <div className={styles.modalAction}>
          <div className='flex flex-col gap-10 items-center w-full h-full justify-center'>
            <CustomDropDown
              options={options}
              onChange={handleDropdownChange}
              placeholder='Select Deactivation Reason'
            />
            {/* Button */}
            <button
              onClick={() => {
                deleHandler && deleHandler();
                setIsOpen(false);
              }}
              className='bg-red-600 px-3 py-3 rounded-md hover-transition text-white'>
              {label}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDeactivateUser;
