/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {useCallback, useEffect, useState} from 'react';
import {useVendor} from '../../../app/hooks';
import {useNavigate} from 'react-router-dom';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import Pagination from '../../../components/ui/Pagination';
import VendorPaymentTable from '../../../components/VendorPaymentTable';
import CustomDatePicker from '../../../components/forms/inputs/selectInput/CustomDatePicker';
import {isWithinInterval, parseISO} from 'date-fns';
import {AdminServices} from '../../../redux/services/admin/admin';

const VendorPaymentHistory = () => {
  localStorage.setItem('currentPath', '/vendor/orders');
  const [isLoading, setIsLoading] = useState(false);
  const [settlements, setSettlements] = useState<any>([]);
  const profile = useVendor().vendor;
  const navigate = useNavigate();
  // Query
  const [query, setQuery] = useState('');
  const keys = ['accountNo', 'createdAt'];
  const search = (data: any) => {
    return data?.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };

  // Sort --------------------
  const [openSort, setOpenSort] = useState(false);

  const [sortingOption, setSortingOption] = useState<keyof any>('AccountName');
  const [isAscending, setIsAscending] = useState(true);

  const paymentData: any[] = [];
  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const sortedData = (data: any) => {
    return [...data].sort((a, b) => {
      // Use the selected sorting option
      const sortOrder = isAscending ? 1 : -1;
      const aValue = a[sortingOption];
      const bValue = b[sortingOption];

      // Compare values based on the sorting order
      if (aValue < bValue) return -1 * sortOrder;
      if (aValue > bValue) return 1 * sortOrder;
      return 0;
    });
  };

  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  // const currentTableData = paymentData?.slice(firstPageIndex, lastPageIndex);

  // console.log(currentTableData);
  const totalPages = Math.ceil(paymentData?.length / PageSize);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Filter Start and End date
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const filteredOrdersByDate = useCallback(
    (data: any) => {
      if (!startDate || !endDate) {
        return data;
      }

      return data.filter((order: any) => {
        const orderDate = parseISO(order.createdAt);
        return isWithinInterval(orderDate, {start: startDate, end: endDate});
      });
    },
    [startDate, endDate]
  );

  const totalFilteredData = search(filteredOrdersByDate(settlements));
  const sortedFilteredData = sortedData(totalFilteredData);
  const currentTableData = sortedFilteredData.slice(
    firstPageIndex,
    lastPageIndex
  );

  // Get Settlements
  const token = useVendor().token;
  const user = useVendor().vendor;
  useEffect(() => {
    getSettlements();
  }, []);

  const getSettlements = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getSettlements(token);
      if (response.data.status === 'success') {
        const res = response?.data.data.details;
        const data = res.filter((item: any) => item.vendorId === user.id);
        const filterByPaid = data.filter((item: any) => item.status === 'paid');
        setSettlements(filterByPaid);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
    }
  };
  return (
    <AppContainer>
      <div className='xl:w-[90%] md:w-[95%] w-full pt-4'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Payments</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>PAYMENTS</h2>
          </Title>
          <div className='flex flex-row items-center gap-4 justify-end '>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center sm:w-56 w-36 rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div className='sm:block hidden'>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div className='sm:block hidden'>
              <ProfileImage
                onClick={() => navigate('../vendor/profile')}
                profileImage={profile?.vendorImg}
              />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='sm:flex hidden flex-row items-center justify-end gap-6 pt-6'>
          {/* Sort */}
          <div
            onClick={() => setOpenSort(!openSort)}
            className='flex flex-row items-center gap-4 cursor-pointer'>
            <div className='font-poppinsMedium'>Sort by:</div>
            <div className='relative'>
              <div className='flex flex-row items-center gap-10'>
                <p className='text-[#868FE1] text-xs font-poppinsMedium'>
                  {sortingOption === 'accountName'
                    ? 'Account Name'
                    : 'Transaction Date'}
                </p>
                <BiChevronDown size={20} color='#868FE1' />
              </div>
              {/* Sort DropDown */}
              {openSort && (
                <div
                  onClick={() => setOpenSort(false)}
                  className='bg-white w-full px-2 py-2 flex flex-col items-start absolute rounded-md shadow-md top-8 z-50 text-xs gap-2'>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('accountName')}>
                    Account Name
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('createdAt')}>
                    Transaction Date
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Date Filter */}

          <div className=''>
            <CustomDatePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </div>
        </div>
        <div className='sm:p-4 p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <VendorPaymentTable payments={search(currentTableData)} />
            )}
            {/* Pagination */}
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;

export default VendorPaymentHistory;
