import {IOtherInfo} from '../../../../../types';
import {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {regexValidation} from '../../../../../components/Helpers/regexValidation';
import {BsInfo} from 'react-icons/bs';
import InputFieldAuth from '../../../../../components/forms/inputs/inputField/InputFieldAuth';
const OtherInfo = ({phone, confirmPassword, password, r}: IOtherInfo) => {
  const [isValid, setIsValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const handleStep = () => {
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
    } else {
      r.nextStep();
    }
  };

  const [phoneFocus, setPhoneFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [confirmPFocus, setConfirmPFocus] = useState(false);

  const phoneRef = useRef<HTMLInputElement>(null);

  // Password
  useEffect(() => {
    const isPasswordValid = regexValidation.validatePassword(password);
    const isPhoneValid = regexValidation.validPhoneNumber(phone);
    setIsValid(isPasswordValid);
    setIsPhoneValid(isPhoneValid);
  }, [password, phone]);

  // Check Validation
  useEffect(() => {
    phoneRef.current?.focus();
  }, []);
  return (
    <div className='flex flex-col gap-2'>
      <InputFieldAuth
        label='Phone Number'
        name='phone'
        type='text'
        placeholder='Phone Number'
        value={phone}
        required={true}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        onFocus={() => setPhoneFocus(true)}
        onBlur={() => setPhoneFocus(false)}
        ariaInvalid={isPhoneValid ? 'false' : 'true'}
        Inputref={phoneRef}
      />
      <p
        id='uidnote'
        className={
          phoneFocus && !isPhoneValid
            ? 'instructions inline-flex'
            : 'offscreen inline-flex'
        }>
        <BsInfo size={24} />
        Please enter valid Phone NUmber
      </p>
      {/* Password */}
      <InputFieldAuth
        name='password'
        label='Password'
        type='password'
        placeholder='************'
        value={password}
        required={true}
        password
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        onFocus={() => setPasswordFocus(true)}
        onBlur={() => setPasswordFocus(false)}
        ariaInvalid={isValid ? 'false' : 'true'}
      />
      <p
        id='uipwd'
        className={
          passwordFocus && !isValid
            ? 'instructions text-xs'
            : 'offscreen text-xs'
        }>
        <span className='flex flex-row items-center'>
          <BsInfo size={20} />
          Minimum of 8 and Maximum of 16 characters.
        </span>
        <span className='px-2'>
          Must include uppercase and lowercase letters, a number and a special
          character.
          {/* (?=.*?[#?!@$%^&*-]) */}
        </span>
        <br />
        <span className='px-2'>Must not include whitespace.</span>
        <br />
        <span className='px-2'>Allowed special characters</span>
        <span className='pl-2 tracking-wide'>#?!@$%^&*-</span>
      </p>

      {/* Confirm */}
      <InputFieldAuth
        name='confirmPassword'
        label='Confirm Password'
        type='password'
        placeholder='************'
        value={confirmPassword}
        required={true}
        password
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        onFocus={() => setConfirmPFocus(true)}
        onBlur={() => setConfirmPFocus(false)}
      />
      <p
        id='uipwd'
        className={
          confirmPFocus && !isValid
            ? 'instructions text-xs'
            : 'offscreen text-xs'
        }>
        <span className='flex flex-row items-center'>
          <BsInfo size={20} />
          Minimum of 8 and Maximum of 16 characters.
        </span>
        <span className='px-2'>
          Must include uppercase and lowercase letters, a number and a special
          character.
          {/* (?=.*?[#?!@$%^&*-]) */}
        </span>
        <br />
        <span className='px-2'>Must not include whitespace.</span>
        <br />
        <span className='px-2'>Allowed special characters</span>
        <span className='pl-2 tracking-wide'>#?!@$%^&*-</span>
      </p>
      {/*Button */}
      <div className='flex flex-row items-center justify-between py-4 gap-4'>
        <button
          className='bg-primary text-white sm:px-20 w-full py-3 transition-hover rounded-md'
          onClick={() => r.prevStep()}>
          Prev
        </button>
        <button
          className='bg-primary text-white sm:px-20 w-full py-3 transition-hover rounded-md disabled:opacity-60'
          disabled={
            !isPhoneValid || !isValid || !confirmPassword ? true : false
          }
          onClick={handleStep}>
          Next
        </button>
      </div>
    </div>
  );
};

export default OtherInfo;
