/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {COLORS} from '../../assets/color';
import {useAdminVendor, useAppDispatch} from '../../app/hooks';
import ViewVendor from '../../pages/dashboard/admin/modal/ViewVendor';
import {IVendor} from '../interfaces/vendors';
import {setSingleAdminVendor} from '../../redux/slices/vendor/adminVendorSlice';
import ConfirmDeactivate from '../modal/ConfirmDeactivate';
import {BiChevronDown} from 'react-icons/bi';
import {DefaultImage} from '../../assets/images';
import ConfirmDeactivateUser from '../modal/ConfirmDeactivateUser';
import Spinner from '../spinner/Spinner';

interface IVendorTable {
  vendors: IVendor[];
  onDeactivate: (id: any, reason: string) => void;
  onActivate: (str: any) => void;
  isActionLoading: boolean;
}
const VendorTable = ({
  vendors,
  onActivate,
  onDeactivate,
  isActionLoading,
}: IVendorTable) => {
  const [showView, setShowView] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showActivate, setShowActivate] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const dispatch = useAppDispatch();
  const singleVendor = useAdminVendor().adminVendor;

  // onViewProduct
  const viewHandler = async (id: any) => {
    vendors.filter((t: any) => t.id === id);
  };

  type IDropDownData = {
    id: number;
    name: string;
    onShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  };

  const dropDownData: IDropDownData[] = [
    {
      id: 1,
      name: 'Activate',
      onShowModal: setShowActivate,
    },
    {
      id: 2,
      name: 'Deactivate',
      onShowModal: setShowDeactivate,
    },
  ];

  // Handle Drop Down
  const options = [
    {
      value: 'Non-compliance with Policies',
      label: 'Non-compliance with Policies',
    },
    {value: 'Poor Product Quality', label: 'Poor Product Quality'},
    {
      value: 'Inadequate Inventory Management',
      label: 'Inadequate Inventory Management',
    },
    {value: 'Delayed Order Fulfillment', label: 'Delayed Order Fulfillment'},
    {value: 'Fraudulent Activities', label: 'Fraudulent Activities'},
    {
      value: 'Violation of Terms of Service',
      label: 'Violation of Terms of Service',
    },
    {value: 'Inactive Vendor Account', label: 'Inactive Vendor Account'},
    {value: 'Requested by Vendor', label: 'Requested by Vendor'},
    {
      value: 'Breached Contract Agreement',
      label: 'Breached Contract Agreement',
    },
  ];
  const [selectedReason, setSelectedReason] = useState('');
  const handleDropdownChange = (selectedValue: string) => {
    // console.log('Selected Value:', selectedValue);
    // Implement your logic here when the dropdown value changes
    setSelectedReason(selectedValue);
  };
  return (
    <>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <ViewVendor setIsOpen={setShowView} />
        </div>
      )}
      <div>
        {isActionLoading && <Spinner />}
        <table className='mt-6 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-16 rounded-tl-md'>Image</th>
              <th className='w-16'>First Name</th>
              <th className='w-16'>Last Name</th>
              <th className='w-16'>Email</th>
              <th className='w-16'>Phone</th>
              <th className='w-16'>Status</th>
              <th className='w-16 rounded-tr-md '>View More</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-orange-100'>
            {vendors.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={7}>
                  <span className='text-xl text-primary font-semibold'>
                    No Vendor available
                  </span>
                </td>
              </tr>
            )}
            <>
              {vendors.map((vendor: IVendor) => {
                return (
                  <tr className='overflow-x-auto' key={vendor.id}>
                    <td className='h-20'>
                      <div className='flex justify-center'>
                        <div className='lg:w-16 w-8 lg:h-16 h-8 rounded-full self-center'>
                          {vendor.vendorImg !== null ? (
                            <img
                              src={vendor.vendorImg}
                              alt=''
                              className='object-cover w-full rounded-full h-full'
                            />
                          ) : (
                            <img
                              src={DefaultImage}
                              alt='profile-def'
                              className='rounded-full w-full h-full object-cover'
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='w-10 text-center'>{vendor.firstName}</td>
                    <td className='w-10 text-center'>{vendor.lastName}</td>
                    <td className='w-10 text-center'>{vendor.email}</td>
                    <td className='w-10 text-center'>{vendor.phone}</td>
                    {/* Status */}
                    <td className='w-16'>
                      {/* Status */}
                      <div className='flex flex-row justify-evenly items-center h-10'>
                        {vendor.isActive === true ? (
                          // Deactivate
                          <div className='relative'>
                            <div
                              onClick={() => {
                                setShowActivateModal(!showActivateModal);
                                setActiveId(vendor.id);
                              }}
                              className='px-2 py-1 bg-[#E6FCE5] text-success flex flex-row gap-1 items-center rounded-md justify-center cursor-pointer shadow-sm'>
                              <span>Activated</span>
                              <BiChevronDown
                                color={COLORS.success}
                                size={22}
                                style={{cursor: 'pointer'}}
                              />
                            </div>
                            {showActivateModal && activeId === vendor.id && (
                              <div
                                onClick={() => setShowActivateModal(false)}
                                className='bg-white w-full px-2 py-2 flex-col items-center absolute rounded-md shadow-md top-8 z-50'>
                                {dropDownData.map((item, index) => (
                                  <p
                                    key={index}
                                    onClick={() => {
                                      if (item.name === 'Deactivate') {
                                        setShowActivateModal(false);
                                        item.onShowModal(true);
                                        dispatch(setSingleAdminVendor(vendor));
                                      }
                                    }}
                                    className={
                                      item.name === 'Activate'
                                        ? 'text-success opacity-40 pb-2'
                                        : 'text-red-500 font-poppinsMedium cursor-pointer'
                                    }>
                                    {item.name}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          // Activate
                          <div className='relative'>
                            <div
                              onClick={() => {
                                setShowDeactivateModal(!showDeactivateModal);
                                setActiveId(vendor.id);
                              }}
                              className='px-2 py-1 bg-[#FEEFEE] text-red-500 flex flex-row gap-1 items-center rounded-md justify-center cursor-pointer shadow-sm'>
                              <span>Deactivate</span>
                              <BiChevronDown
                                color={COLORS.error}
                                size={22}
                                style={{cursor: 'pointer'}}
                              />
                            </div>
                            {showDeactivateModal && activeId === vendor.id && (
                              <div
                                onClick={() => setShowDeactivateModal(false)}
                                className='bg-white w-full px-2 py-2 flex-col items-center absolute rounded-md shadow-md top-8 z-50'>
                                {dropDownData.map((item, index) => (
                                  <p
                                    key={index}
                                    onClick={() => {
                                      if (item.name === 'Activate') {
                                        setShowDeactivateModal(false);
                                        dispatch(setSingleAdminVendor(vendor));
                                        item.onShowModal(true);
                                      }
                                    }}
                                    className={
                                      item.name === 'Deactivate'
                                        ? 'text-red-500 opacity-40 pt-2'
                                        : 'text-success font-poppinsMedium cursor-pointer'
                                    }>
                                    {item.name}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {showDeactivate && (
                        <ConfirmDeactivateUser
                          handleDropdownChange={handleDropdownChange}
                          setIsOpen={setShowDeactivate}
                          onAction={() =>
                            onDeactivate(singleVendor.id, selectedReason)
                          }
                          options={options}
                          label={'Deactivate'}
                        />
                      )}
                      {showActivate && (
                        <ConfirmDeactivate
                          setIsOpen={setShowActivate}
                          onAction={() => onActivate(singleVendor.id)}
                          bgColor={'green'}
                          label={'Activate'}
                          activeLabel='activate'
                        />
                      )}
                    </td>
                    {/* Action */}
                    <td className='w-16'>
                      <div className='flex flex-row justify-evenly items-center h-10'>
                        {/* View  */}
                        <span
                          onClick={() => {
                            viewHandler(vendor.id);
                            dispatch(setSingleAdminVendor(vendor));
                            setShowView(true);
                          }}
                          className='border-b-[1.7px] border-b-primary text-primary font-poppinsMedium pb-1 cursor-pointer'>
                          View
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VendorTable;
