import {HambergerMenu, Profile, SearchNormal1} from 'iconsax-react';
import {IoIosHeartEmpty} from 'react-icons/io';
import {NavLink, useNavigate} from 'react-router-dom';
import {Logo, ThePeopleStore} from '../../assets/images';
import {INavBarMenu} from '../../common/menuItems';
import {COLORS} from '../../assets/color';
import {CiShoppingCart} from 'react-icons/ci';
import {customerUrl, vendorUrl} from '../../constant';

interface INavbar {
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  searchQuery?: string;
  NavItem: INavBarMenu[];
  handleSearch?: any;
  handleIconClick?: any;
}
const Navbar = (p: INavbar) => {
  const navigate = useNavigate();

  return (
    <>
      <div className='bg-transparent w-full h-[4.5rem] pt-6 sm:block hidden'>
        {/* Nave Items */}
        <div className='flex flex-row justify-between items-center px-0'>
          {/* Logo and Menu Items */}
          <div className='flex flex-row gap-6 items-center'>
            {/* logo */}
            <div
              className='logo-img w-28 h-full cursor-pointer'
              onClick={() => navigate('/')}>
              <img
                src={ThePeopleStore}
                alt='logo'
                className='object-cover w-full h-full'
              />
            </div>
            {/* Menu Items */}
            <div className='flex flex-row justify-between items-center gap-6'>
              {p.NavItem.map((link, i) => (
                <NavMenu key={link.key} link={link} i={i} />
              ))}
            </div>
          </div>
          {/*  Query and Actions Icon */}
          <div className='flex flex-row gap-6 items-center'>
            {/* Query */}
            <div className='bg-[#F5F5F5] flex flex-row items-center xl:w-[30rem] w-[15rem] rounded-md py-1 px-4 text-xs'>
              <div onClick={p.handleIconClick} className='icon cursor-pointer'>
                <SearchNormal1 size={20} />
              </div>
              <input
                type='search'
                placeholder='Search products, services, categories'
                className=' p-2 text-[#343A40] h-full w-full focus:border-transparent focus:shadow-none focus:outline-none bg-transparent font-poppinsRegular'
                onChange={(e) => p.setQuery(e.target.value)}
                onKeyDown={p.handleSearch}
                value={p.searchQuery}
              />
            </div>
            {/* Action Icons */}
            <div className='flex flex-row justify-between items-center gap-4 '>
              {/* Wishlist */}
              <NavLink
                className='lg:flex flex-row items-center gap-1 cursor-pointer hidden'
                to={`${customerUrl}/account/saveItems`}>
                <IoIosHeartEmpty size={22} color={'#f68b1e'} />

                <p className=''>Wishlist</p>
              </NavLink>
              {/* Cart */}
              <NavLink
                className='flex flex-row items-center gap-1 cursor-pointer'
                to={`${customerUrl}/cart`}>
                <CiShoppingCart size={22} color={COLORS.primary} />
                <p className='lg:flex hidden'>Cart</p>
              </NavLink>
              {/* Profile Icon */}

              <NavLink
                to={`${customerUrl}/account/profile`}
                className='bg-[#FAFAFA] w-10 h-10 rounded-full text-center flex items-center justify-center cursor-pointer'>
                <Profile size={18} color={COLORS.primary} variant='Bold' />
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className='bg-transparent w-full py-4 sm:hidden block'>
        {/* Nave Items */}
        <div className='flex flex-row justify-between items-center px-0 gap-2'>
          {/* Logo and Menu Items */}
          <div className='flex flex-row gap-4 items-center'>
            <HambergerMenu variant='Linear' size={22} />
            {/* logo */}
            <div
              className='logo-img w-10 h-full cursor-pointer'
              onClick={() => navigate('/')}>
              <img
                src={Logo}
                alt='logo'
                className='object-cover w-full h-full'
              />
            </div>
          </div>
          {/*  Query and Actions Icon */}
          <div className='flex flex-row gap-4 items-center'>
            {/* Query */}
            <div className='bg-[#F5F5F5] flex flex-row items-center w-full rounded-md py-1 px-4 text-xs'>
              <div onClick={p.handleIconClick} className='icon cursor-pointer'>
                <SearchNormal1 size={20} />
              </div>
              <input
                type='search'
                placeholder='Search products, services, categories'
                className=' p-2 text-[#343A40] h-full w-full focus:border-transparent focus:shadow-none focus:outline-none bg-transparent font-poppinsRegular'
                onChange={(e) => p.setQuery(e.target.value)}
                onKeyDown={p.handleSearch}
                value={p.searchQuery}
              />
            </div>
            {/* Action Icons */}
            <div className='flex flex-row justify-between items-center gap-4 '>
              {/* Profile Icon */}

              <NavLink
                to={`${customerUrl}/account/profile`}
                className='bg-[#FAFAFA] w-10 h-10 rounded-full text-center flex items-center justify-center cursor-pointer'>
                <Profile size={18} color={COLORS.primary} variant='Bold' />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

type INavBar = {
  link: any;
  i: any;
};

function NavMenu({link}: INavBar) {
  return (
    <div>
      <NavLink
        to={link.path}
        target={link.path === vendorUrl ? '_blank' : ''}
        // target='_blank'
        className={({isActive}) => {
          return (
            ' cursor-pointer py-1 font-poppinsRegular' +
            (!isActive
              ? 'text-black'
              : 'text-black border-b-[1.5px] border-primary')
          );
        }}>
        {link.item}
      </NavLink>
    </div>
  );
}
