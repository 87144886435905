import {Link, useNavigate} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {useAppDispatch} from '../../../../app/hooks';
import {regexValidation} from '../../../../components/Helpers/regexValidation';
import {AuthServices} from '../../../../redux/services/admin/auths';
import {Loader, axiosError} from '../../../../components/Helpers';
import {setAdminCredentials} from '../../../../redux/slices/admin/userSlice';
import InputField from '../../../../components/forms/inputs/inputField/InputField';
import {BsInfo} from 'react-icons/bs';
import Button from '../../../../components/ui/Button';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [validEmailPhone, setValidEmailPhone] = useState(false);
  const [emailPhoneFocus, setEmailPhoneFocus] = useState(false);

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const emailPhoneRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    emailPhone: '',
    password: '',
  });

  // Check Validation
  useEffect(() => {
    emailPhoneRef.current?.focus();
  }, []);

  // REGEX Validations
  useEffect(() => {
    const isEmailPhoneValid = regexValidation.validEmailPhone(state.emailPhone);
    const isPasswordValid = regexValidation.adminValidatePassword(
      state.password
    );
    setValidEmailPhone(isEmailPhoneValid);
    setValidPwd(isPasswordValid);
  }, [state.emailPhone, state.password]);

  // HandleChange
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((p: any) => ({
      ...p,
      [name]: value,
    }));
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      emailPhone: state.emailPhone,
      password: state.password,
    };
    try {
      const response = await AuthServices.login(data);
      if (response.data.status === 'success') {
        const res = response.data;
        dispatch(setAdminCredentials(res.data));
        // console.log(response);
        navigate('../admin/dashboard');
        toast.success(res.data.message || 'Login Successfully');
        // console.log(`Response`);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  return (
    <div className='w-full pt-4 md:px-2 px-4 font-poppinsRegular'>
      <div className='w-full h-full flex flex-row items-center justify-center'>
        {/*  LOGIN FORM HERE */}
        <div className='grid place-items-center pt-2 w-full'>
          <div className='w-full'>
            <h3 className='text-base md:text-lg font-circularStdMedium text-center pb-3 font-poppinsMedium'>
              Welcome Admin
            </h3>
            <h4 className=' text-xs md:text-sm font-poppinsRegular text-center pb-3 text-[#777777] w-[80%] m-auto'>
              Enter your login details to access your dashboard
            </h4>
            <form className='w-full'>
              <InputField
                label='Email or Phone Number'
                name='emailPhone'
                type='text'
                Inputref={emailPhoneRef}
                placeholder='Enter Email or Phone Number'
                value={state.emailPhone}
                required={true}
                ariaInvalid={validEmailPhone ? 'false' : 'true'}
                ariaDescribedby='uiuser'
                onFocus={() => setEmailPhoneFocus(true)}
                onBlur={() => setEmailPhoneFocus(false)}
                onChange={handleChange}
                style={{height: '100%'}}
              />
              <p
                id='uidnote'
                className={
                  emailPhoneFocus && !validEmailPhone
                    ? 'instructions'
                    : 'offscreen'
                }>
                <BsInfo size={24} />
                Please enter valid Email or Phone Number
              </p>

              <InputField
                name='password'
                label='Password'
                type='password'
                placeholder='************'
                value={state.password}
                required={true}
                ariaInvalid={validPwd ? 'false' : 'true'}
                ariaDescribedby='uipwd'
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                password
                onChange={handleChange}
                style={{height: '100%'}}
              />
              <p
                id='uipwd'
                className={
                  pwdFocus && !validPwd
                    ? 'instructions text-xs'
                    : 'offscreen text-xs'
                }>
                <BsInfo size={24} />
                Minimum of 8 and Maximum of 16 characters.
                <br />
                Must include uppercase and lowercase letters, a number and a
                special character.
                <br />
                <br />
                Must not include whitespace.
                <br />
                Allowed special characters
                <span className='pl-2 tracking-wide'>#?!@$%^&*-</span>
                {/* (?=.*?[#?!@$%^&*-]) */}
              </p>

              <div className='flex justify-end py-2 items-center'>
                <div className='cursor-pointer text-error text-xs text-red-500'>
                  <Link to={'../forgotPassword'}>Forgot Password?</Link>
                </div>
              </div>
              <div className='py-2'></div>
              <Button
                label={
                  isLoading ? (
                    <div>
                      <Loader color={'#fff'} />
                    </div>
                  ) : (
                    'Sign in'
                  )
                }
                width='full'
                onClick={handleSubmit}
                // onClick={() => navigate('../admin/dashboard')}
              />

              {/* Don't have an account */}
              {/* <div className='flex  justify-center text-sm text-center px-12'>
                <p className='cursor-pointer text-menuIcon'>
                  Don't have an account? &nbsp;
                  <span
                    className='font-poppinsSemibold text-primary'
                    onClick={() => {
                      navigate('/admin/reg');
                    }}>
                    Sign up
                  </span>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
