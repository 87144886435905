import {api} from '../api';

// Register
// const register = (data: Object) => {
//   return fetchApi({
//     method: 'POST',
//     url: 'vendor/register',
//     body: data,
//     header: {
//       'Content-type': 'application/json',
//     },
//   });
// };

// Login
const login = (data: Object) => {
  return api.post('vendor/login', data);
};
// Login
const register = (data: Object) => {
  return api.post('vendor/register', data);
};
// Verify Email
const verifyEmail = (data: Object) => {
  return api.patch('vendor/verification', data);
};

// Resend OTP
const resendOTP = (data: Object) => {
  return api.post('vendor/otp', data);
};

// Forgot Password
const forgotPassword = (data: Object) => {
  return api.post('vendor/forgot-password', data);
};

// Reset Password
const resetPassword = (token: any, data: Object) => {
  return api.post(`vendor/reset-password/${token}`, data);
};

export const AuthService = {
  register,
  login,
  verifyEmail,
  resendOTP,
  forgotPassword,
  resetPassword,
};
