/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddIcon,
  ChevronDown,
  DefaultImage,
  PencilSm,
} from '../../../assets/images';
import Input from '../../../components/dashboard/profile/input';
import styled from 'styled-components';
import Select from '../../../components/dashboard/profile/select';
import Button from '../../../components/dashboard/profile/button';
import {Helmet} from 'react-helmet';
import {useState, useEffect, ChangeEvent} from 'react';
import {City, Country, State} from 'country-state-city';
import {useVendor} from '../../../app/hooks';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {axiosError} from '../../../components/Helpers';
import {Loader} from '../../../assets/svg';
import {COLORS} from '../../../assets/color';
import {ProgressCircle} from '@tremor/react';
import {IFormData} from './profile.interface';
import ProfileSkeleton from '../../../components/ui/skeletonUI/ProfileSkeleton';
import {bankData} from '../../../utils/data';

const Profile = () => {
  const token = useVendor().token;
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isProfileImageLoading, setIsProfileImageLoading] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormData>({
    firstName: '',
    lastName: '',
    country: '',
    vendorImg: null,
    businessName: '',
    businessType: '',
    regNumber: '',
    email: '',
    accountName: '',
    accountNumber: '',
    bankName: '',
    state: '',
    city: '',
    address: '',
    phone: '',
    // progress: 0,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    // Check if the input is a number and has at most 10 digits
    // Validate accountNumber separately
    if (name === 'accountNumber') {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (numericValue.length <= 10) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: numericValue,
        }));
      }
      return;
    }

    // Validate phoneNumber separately
    if (name === 'phoneNumber') {
      const numericValueNUmber = value.replace(/\D/g, ''); // Remove non-numeric characters
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValueNUmber,
      }));
      return;
    }

    // // Validate email
    // if (name === 'email') {
    //   const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/.test(value);
    //   if (!isValidEmail) {
    //     toast.error('Enter Valid Email');
    //   }
    // }

    // Update the formData state for other fields
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [imageUrl, setImageUrl] = useState<any>();
  const [imagePreview, setImagePreview] = useState<string>('');
  const [countryCode, setCountryCode] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [bankId, setBankId] = useState<string | null>(null);

  // Get country from country-state-city
  const countries = Country.getAllCountries();

  const states = State.getStatesOfCountry(countryCode);
  // City.getCitiesOfState(countryCode, stateCode)
  const cities = City.getCitiesOfState(countryCode, stateCode);

  //  Handle Image Upload
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      vendorImg: file || null,
    }));
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Get Profile
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setIsProfileLoading(true);
    try {
      const response = await VendorServices.getProfile(token);
      if (response.data.status === 'success') {
        const res = response.data.data.vendor;
        // console.log(res);
        setFormData({
          accountName: res.accountName,
          address: res.address,
          accountNumber: res.accountNumber,
          bankName: res.bankName,
          businessName: res.businessName,
          businessType: res.businessType,
          city: res.city,
          country: res.country,
          email: res.email,
          firstName: res.firstName,
          lastName: res.lastName,
          phone: res.phone,
          state: res.state,
          vendorImg: res.vendorImg,
          regNumber: res.regNumber,
          // progress: progressValue,
        });
        setImageUrl(res);
        setIsProfileLoading(false);
        // console.log(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      setIsProfileLoading(false);
    }
  };
  // Progress
  const calculateProgress = (): number => {
    const {regNumber, progress, ...rest} = formData;
    // console.log('FORM', formData);
    const totalFields = Object.keys(rest).length;
    // console.log(totalFields);
    const filledFields = Object.values(rest).filter(
      (value) => value !== '' && value !== null
    ).length;
    // console.log('Filled', filledFields, rest);
    const progressValue = (filledFields / totalFields) * 100;
    return Math.ceil(progressValue);
  };
  const progress = calculateProgress();

  // Update Profile
  const updateHandler = async () => {
    if (formData.accountNumber?.length !== 10) {
      toast.error('Invalid account Number');
      return;
    }
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/.test(
      formData.email || ''
    );
    if (!isValidEmail) {
      toast.error('Please enter a valid email address.');
      return;
    }

    // Loading
    setIsLoading(true);
    const data = {...formData, progress};
    // include the  progress  field in the request payload if it's 100.
    if (progress === 100) {
      // Send the complete formData to the backend
      try {
        const res = await VendorServices.updateProfile(token, data);
        if (res.data.status === 'success') {
          // dispatch(setVendorCredentials(res.data.data.vendor));
          navigate('../vendor/dashboard');
          toast.success(
            `Congrats, you've successfully completed your profile.`
          );
          setIsLoading(false);
        }
      } catch (error: any) {
        axiosError(error.response);
        setIsLoading(false);
      }
    } else {
      // Send the formData excluding progress to the backend
      // const {progress, ...patchData} = formData;
      try {
        const res = await VendorServices.updateProfile(token, data);
        if (res.data.status === 'success') {
          // dispatch(setVendorCredentials(res.data.data.vendor));
          navigate('../vendor/dashboard');
          toast.success(
            res.data.data.message || 'Profile successfully Updated.'
          );
          setIsLoading(false);
        }
      } catch (error: any) {
        axiosError(error.response);
        setIsLoading(false);
      }
    }
  };

  // Submit Image to Cloud
  const submitImage = async () => {
    setIsProfileImageLoading(true);
    const data = {
      image: formData.vendorImg,
    };
    try {
      const imageRes = await VendorServices.imageUpload(token, data);
      // console.log(imageRes);
      if (imageRes.data.status === 'success') {
        // console.log('Image', imageRes.data.data.url);
        setFormData((p) => ({
          ...p,
          vendorImg: imageRes.data.data.url,
        }));
        toast.success(imageRes.data.data.message);
        setIsProfileImageLoading(false);
      }
    } catch (error: any) {
      console.log(error.response);
      axiosError(error.response);
      setIsProfileImageLoading(false);
    }
  };

  // GetBank ID
  const getBankId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let holder = e.target.value.split('-');
    // console.log(e.target.value);
    setFormData((p) => ({
      ...p,
      bankName: e.target.value,
    }));
    setBankId(holder[0]);
  };
  // Verify Account
  // const verifyAccount = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setFormData((p) => ({
  //     ...p,
  //     account_number: e.target.value,
  //   }));
  //   if (e.target.value.length === 10) {
  //     try {
  //       const config = {
  //         headers: {
  //           'Content-type': 'application/json',
  //           Authorization: `Bearer ${process.env.REACT_APP_OKRA_SECRET_ID}`,
  //         },
  //       };

  //       const body = {
  //         bank: bankId,
  //         nuban: e.target.value,
  //       };

  //       const response = axios.post(
  //         'https://api.okra.ng/v2/identity/getByNuban/',
  //         body,
  //         config
  //       );

  //       response.then((res) => {
  //         setFormData((p) => ({
  //           ...p,
  //           account_name: res?.data?.data?.customer?.name,
  //         }));
  //       });

  //       // validation
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // Get State
  useEffect(() => {
    const getStates = () => {
      for (let i = 0; i < countries.length; i++) {
        if (formData.country === countries[i].name) {
          setCountryCode(countries[i].isoCode);
        }
      }
    };
    formData.country !== '' && getStates();
  }, [formData.country, countries]);

  // Get City
  useEffect(() => {
    const getCities = () => {
      for (let i = 0; i < states.length; i++) {
        if (formData.state === states[i].name) {
          setStateCode(states[i].isoCode);
        }
      }
    };
    formData.state !== '' && getCities();
  }, [formData.state, states]);
  // City.getCitiesOfState(countryCode, stateCode)

  return (
    <AppContainer>
      <div className='xl:w-[95%] md:w-[98%] w-full pt-4 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Profile</title>
        </Helmet>
        <div className='bg-white p-2 w-full rounded-md'>
          {isProfileLoading ? (
            <ProfileSkeleton />
          ) : (
            // <p className='text-lg'>Loading...</p>
            <>
              <div className='flex lg:flex-row flex-col items-center justify-between px-16 pb-4'>
                {/* profile */}
                <div>
                  <div className=' flex flex-row items-end relative'>
                    <div className='w-[12.5rem] h-[12.5rem]'>
                      {!imagePreview && imageUrl?.vendorImg === null && (
                        <img
                          src={DefaultImage}
                          alt='profile-def'
                          className='rounded-full w-full h-full object-cover'
                        />
                      )}

                      {!imagePreview && imageUrl?.vendorImg !== null && (
                        <img
                          src={imageUrl?.vendorImg}
                          alt='profile-url'
                          className='rounded-full w-full h-full object-cover'
                        />
                      )}
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt='profile-prev'
                          className='rounded-full w-full h-full object-cover'
                        />
                      )}
                    </div>

                    <div
                      className='w-[2.5rem] h-[2.5rem] rounded-[1rem] right-4 z-10 absolute cursor-pointer flex'
                      onClick={() => ''}>
                      <img
                        src={AddIcon}
                        alt='add-icon'
                        width={'100%'}
                        height={'100%'}
                      />
                      <input
                        type='file'
                        style={{opacity: 0}}
                        onChange={handleImageUpload}
                        className='absolute z-10 right-[0%] -left-[-10%] bottom-0 cursor-pointer w-[33px] h-[30px] pb-[40px] border-2'
                        title='Upload'
                      />
                    </div>
                  </div>
                  <div
                    className='bg-primary py-3 mt-3 text-white rounded cursor-pointer text-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300'
                    onClick={submitImage}>
                    <p>
                      {isProfileImageLoading
                        ? 'Uploading Image...'
                        : 'Upload Image'}
                    </p>
                  </div>
                </div>
                <div className='xl:block hidden pb-6'>
                  {isLoading ? (
                    <div className='flex items-center gap-5 justify-center'>
                      <Loader color={COLORS.primary} width={'10%'} />
                    </div>
                  ) : (
                    <button
                      type='submit'
                      className='text-white font-bold px-3 py-2 bg-primary rounded-lg '
                      onClick={updateHandler}>
                      SAVE
                    </button>
                  )}
                </div>
                <div className='flex flex-row justify-center gap-10 px-10 py-6 items-center'>
                  {/* Card */}

                  <div>
                    {progress === 100 ? (
                      <span className='font-bold text-green-500'>Congrats</span>
                    ) : (
                      <span className='font-bold text-gray-500'>
                        Please complete your profile to upload product.
                      </span>
                    )}
                  </div>
                  <div className='rounded-lg pb-6 lg:pr-20 relative'>
                    <ProgressCircle
                      value={progress}
                      size='xl'
                      color={progress === 100 ? 'green' : 'amber'}
                      strokeWidth={15}
                      tooltip='Profile Status'>
                      <div className='flex flex-col justify-center items-center'>
                        <p className='text-lg font-medium'>{`${progress}%`}</p>
                        <p className='text-base font-medium'>Complete</p>
                      </div>
                    </ProgressCircle>
                  </div>
                </div>
              </div>
              <div className='flex flex-row justify-center w-full'>
                <span className='font-bold  text-primary '>
                  PERSONAL INFORMATION
                </span>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-center w-full h-auto overflow-auto py-4 md:hidden xl:grid'>
                {/* First */}
                <div className='sm:space-y-20 space-y-10 sm:mb-0 mb-10'>
                  <Input
                    label='COMPANY NAME'
                    icon={PencilSm}
                    pattern=''
                    handleChange={(e: any) => handleInputChange(e)}
                    name='businessName'
                    valueField={formData.businessName || ''}
                    required
                  />
                  <Input
                    label='REGISTRATION NUMBER'
                    icon={PencilSm}
                    pattern=''
                    handleChange={(e: any) => handleInputChange(e)}
                    name='regNumber'
                    valueField={formData.regNumber || ''}
                  />
                  <Input
                    label='BUSINESS TYPE'
                    icon={PencilSm}
                    pattern=''
                    name='businessType'
                    handleChange={handleInputChange}
                    valueField={formData.businessType || ''}
                    required
                  />
                  <Select
                    label='CITY'
                    icon={ChevronDown}
                    options={cities}
                    name='city'
                    handleChange={handleInputChange}
                    valueField={formData.city || ''}
                    required
                  />

                  <div className='xl:block hidden pb-6'>
                    {isLoading ? (
                      <div className='flex items-center justify-center px-3 py-2 bg-primary rounded-lg'>
                        <Loader color={COLORS.white} />
                      </div>
                    ) : (
                      <button
                        type='submit'
                        className='text-white font-bold w-full py-2 bg-primary rounded-lg '
                        onClick={updateHandler}>
                        SAVE
                      </button>
                    )}
                  </div>
                </div>
                {/* Second */}
                <div className='sm:space-y-20 space-y-10 sm:mb-0 mb-10'>
                  <Input
                    label='FIRST NAME'
                    icon={PencilSm}
                    name='firstName'
                    handleChange={handleInputChange}
                    valueField={formData.firstName || ''}
                    required
                  />

                  <Input
                    label='EMAIL'
                    icon={PencilSm}
                    name='email'
                    handleChange={handleInputChange}
                    valueField={formData.email || ''}
                    required
                  />
                  <Select
                    label='COUNTRY'
                    icon={ChevronDown}
                    options={countries}
                    name='country'
                    handleChange={handleInputChange}
                    valueField={formData.country || ''}
                    required
                  />
                  <Input
                    label='ADDRESS'
                    icon={PencilSm}
                    pattern=''
                    name='address'
                    handleChange={handleInputChange}
                    valueField={formData.address || ''}
                    required
                  />

                  <Select
                    label='BANK NAME'
                    icon={ChevronDown}
                    options={bankData}
                    name='bankName'
                    handleChange={getBankId}
                    valueField={formData.bankName || ''}
                    required
                  />
                </div>

                {/* Third */}
                <div className='sm:space-y-20 space-y-10 sm:mb-0 mb-4'>
                  <Input
                    label='LAST NAME'
                    icon={PencilSm}
                    pattern=''
                    name='lastName'
                    handleChange={handleInputChange}
                    valueField={formData.lastName || ''}
                    required
                  />

                  <Input
                    label='PHONE NUMBER'
                    icon={PencilSm}
                    pattern=''
                    name='phone'
                    handleChange={handleInputChange}
                    valueField={formData.phone || ''}
                    required
                  />
                  <Select
                    label='STATE'
                    icon={ChevronDown}
                    options={states}
                    name='state'
                    handleChange={handleInputChange}
                    valueField={formData.state || ''}
                    required
                  />

                  <Input
                    label='ACCOUNT NO'
                    icon={PencilSm}
                    pattern=''
                    name='accountNumber'
                    handleChange={handleInputChange}
                    valueField={formData.accountNumber || ''}
                    required
                  />
                  <Input
                    label='ACCOUNT NAME'
                    icon={PencilSm}
                    pattern=''
                    name='accountName'
                    handleChange={handleInputChange}
                    valueField={formData.accountName || ''}
                    required
                  />
                </div>
              </div>
              {/* Col === 2  tablet through mini laptop*/}
              <div className='md:grid xl:hidden hidden md:grid-cols-2 place-items-center w-full h-auto overflow-auto gap-12 py-4'>
                {/* First */}
                {/* <div className='sm:space-y-20 space-y-10 sm:mb-0 mb-10'> */}
                <Input
                  label='FIRST NAME'
                  icon={PencilSm}
                  name='firstName'
                  handleChange={handleInputChange}
                  valueField={formData.firstName || ''}
                  required
                />
                <Input
                  label='LAST NAME'
                  icon={PencilSm}
                  pattern=''
                  name='lastName'
                  handleChange={handleInputChange}
                  valueField={formData.lastName || ''}
                  required
                />
                <Input
                  label='PHONE NUMBER'
                  icon={PencilSm}
                  pattern=''
                  name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.phone || ''}
                  required
                />

                <Input
                  label='EMAIL'
                  icon={PencilSm}
                  name='email'
                  handleChange={handleInputChange}
                  valueField={formData.email || ''}
                  required
                />
                <Input
                  label='COMPANY NAME'
                  icon={PencilSm}
                  pattern=''
                  handleChange={(e: any) => handleInputChange(e)}
                  name='businessName'
                  valueField={formData.businessName || ''}
                  required
                />
                <Input
                  label='REGISTRATION NUMBER'
                  icon={PencilSm}
                  pattern=''
                  handleChange={(e: any) => handleInputChange(e)}
                  name='regNumber'
                  valueField={formData.regNumber || ''}
                />
                <Input
                  label='BUSINESS TYPE'
                  icon={PencilSm}
                  pattern=''
                  name='businessType'
                  handleChange={handleInputChange}
                  valueField={formData.businessType || ''}
                  required
                />
                <Input
                  label='ADDRESS'
                  icon={PencilSm}
                  pattern=''
                  name='address'
                  handleChange={handleInputChange}
                  valueField={formData.address || ''}
                  required
                />
                <Select
                  label='COUNTRY'
                  icon={ChevronDown}
                  options={countries}
                  name='country'
                  handleChange={handleInputChange}
                  valueField={formData.country || ''}
                  required
                />
                <Select
                  label='STATE'
                  icon={ChevronDown}
                  options={states}
                  name='state'
                  handleChange={handleInputChange}
                  valueField={formData.state || ''}
                  required
                />
                <Select
                  label='CITY'
                  icon={ChevronDown}
                  options={cities}
                  name='city'
                  handleChange={handleInputChange}
                  valueField={formData.city || ''}
                  required
                />
                <Input
                  label='ACCOUNT NO'
                  icon={PencilSm}
                  pattern=''
                  name='accountNumber'
                  handleChange={handleInputChange}
                  valueField={formData.accountNumber || ''}
                  required
                />

                <Select
                  label='BANK NAME'
                  icon={ChevronDown}
                  options={bankData}
                  name='bankName'
                  handleChange={getBankId}
                  valueField={formData.bankName || ''}
                  required
                />
                <Input
                  label='ACCOUNT NAME'
                  icon={PencilSm}
                  pattern=''
                  name='accountName'
                  handleChange={handleInputChange}
                  valueField={formData.accountName || ''}
                  required
                />
                <div className='xl:block hidden pb-6'>
                  {isLoading ? (
                    <div className='flex items-center gap-5 justify-center'>
                      <Loader color={COLORS.primary} width={'10%'} />
                    </div>
                  ) : (
                    <button
                      type='submit'
                      className='text-white font-bold px-3 py-2 bg-primary rounded-lg '
                      onClick={updateHandler}>
                      SAVE
                    </button>
                  )}
                </div>
              </div>
              {/* Button */}

              <div className='md:hidden flex flex-row m-auto'>
                <div className='flex flex-row m-auto'>
                  <Button
                    type='submit'
                    className='text-white font-bold h-[3.8rem]'
                    handleSubmit={updateHandler}>
                    {isLoading ? 'Loading...' : 'SAVE'}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  margin-bottom: 3rem;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
  overflow-x: hidden;
`;
export default Profile;
