import {Icon} from '@iconify/react';
import {People, Profile} from 'iconsax-react';
import {AiFillHome, AiOutlineTransaction} from 'react-icons/ai';
import {BsFillBoxFill} from 'react-icons/bs';
import {FaUserSecret} from 'react-icons/fa';
import {FiUsers} from 'react-icons/fi';
import {MdMessage, MdOutlinePendingActions, MdPayments} from 'react-icons/md';
import {RiCoupon3Fill, RiCoupon3Line} from 'react-icons/ri';

export type IMenu = {
  id?: number;
  key: string;
  item: string;
  icon?: React.ReactNode;
  iconTwo?: React.ReactNode;
  path?: string;
  subMenu?: ISubMenu[];
};
export interface ISubMenu {
  id?: number;
  key: string;
  item: string;
  icon?: React.ReactNode;
  iconTwo?: React.ReactNode;
  path?: string;
}

export const appScreens: IMenu[] = [
  {
    id: 1,
    key: 'dashboard',
    item: 'Dashboard',
    path: '/admin/dashboard',
    icon: <AiFillHome size={22} />,
    iconTwo: <AiFillHome size={22} />,
  },
  {
    id: 2,
    key: 'vendors',
    item: 'Vendors',
    path: '/admin/vendors',
    icon: <FiUsers size={22} />,
    iconTwo: <FiUsers size={22} />,
  },
  {
    id: 3,
    key: 'customers',
    item: 'Customers',
    path: '/admin/customers',
    icon: <People size={22} variant='Bold' />,
    iconTwo: <People size={22} variant='Bold' />,
  },
  {
    id: 4,
    key: 'products',
    item: 'Products',
    path: '/admin/products',
    icon: <BsFillBoxFill size={20} />,
    iconTwo: <BsFillBoxFill size={20} />,
  },
  {
    id: 5,
    key: 'orders',
    item: 'Orders',
    path: '/admin/allOrders',
    icon: <MdOutlinePendingActions size={22} />,
    iconTwo: <MdOutlinePendingActions size={22} />,
    subMenu: [
      {
        id: 1,
        key: 'allOrders',
        item: 'All Orders',
        path: '/admin/allOrders',
        icon: <MdOutlinePendingActions size={22} />,
        iconTwo: <MdOutlinePendingActions size={22} />,
      },
      {
        id: 2,
        key: 'cancelledOrders',
        item: 'Cancelled Orders',
        path: '/admin/cancelledOrders',
        icon: <MdOutlinePendingActions size={22} />,
        iconTwo: <MdOutlinePendingActions size={22} />,
      },
    ],
  },
  {
    id: 5,
    key: 'returns',
    item: 'Returns',
    path: '/admin/returns',
    icon: <MdOutlinePendingActions size={22} />,
    iconTwo: <MdOutlinePendingActions size={22} />,
  },
  {
    id: 7,
    key: 'transactions',
    item: 'Transactions',
    path: '/admin/transactions',
    icon: <AiOutlineTransaction size={22} />,
    iconTwo: <AiOutlineTransaction size={22} />,
  },
  {
    id: 8,
    key: 'settlements',
    item: 'Settlements',
    path: '/admin/settlements',
    icon: <MdPayments size={22} />,
    iconTwo: <MdPayments size={22} />,
  },
  {
    id: 9,
    key: 'accounting',
    item: 'Accounting',
    path: '/admin/accounting',
    icon: <Icon icon='map:accounting' fontSize={22} />,
    iconTwo: <Icon icon='map:accounting' fontSize={22} />,
  },
  {
    id: 10,
    key: 'reviews',
    item: 'Reviews',
    path: '/admin/reviews',
    icon: <Icon icon='material-symbols:rate-review-rounded' fontSize={22} />,
    iconTwo: <Icon icon='material-symbols:rate-review-rounded' fontSize={22} />,
  },
  {
    id: 11,
    key: 'coupons',
    item: 'Coupon',
    path: '/admin/coupon',
    icon: <RiCoupon3Line size={22} />,
    iconTwo: <RiCoupon3Fill size={22} />,
  },
  {
    id: 12,
    key: 'usersManagement',
    item: 'User Management',
    path: '/admin/userManagement/admin',
    icon: <FaUserSecret size={22} />,
    iconTwo: <FaUserSecret size={22} />,
    subMenu: [
      {
        id: 1,
        key: 'usersManagementAdmin',
        item: 'Admin',
        path: '/admin/userManagement/admin',
        icon: <FaUserSecret size={22} />,
        iconTwo: <FaUserSecret size={22} />,
      },
      {
        id: 2,
        key: 'usersManagementVendor',
        item: 'Vendor',
        path: '/admin/userManagement/vendor',
        icon: <FaUserSecret size={22} />,
        iconTwo: <FaUserSecret size={22} />,
      },
    ],
  },
];

export const vendorScreens: IMenu[] = [
  {
    id: 1,
    key: 'dashboard',
    item: 'Dashboard',
    path: '/vendor/dashboard',
    icon: <AiFillHome size={22} />,
    iconTwo: <AiFillHome size={22} />,
  },
  {
    id: 2,
    key: 'messages',
    item: 'Messages',
    path: '/vendor/chats',
    icon: <MdMessage size={22} />,
    iconTwo: <MdMessage size={22} />,
  },
  {
    id: 3,
    key: 'products',
    item: 'Products',
    path: '/vendor/products',
    icon: <BsFillBoxFill size={22} />,
    iconTwo: <BsFillBoxFill size={22} />,
  },
  {
    id: 4,
    key: 'orders',
    item: 'Orders',
    path: '/vendor/orders',
    icon: <MdOutlinePendingActions size={22} />,
    iconTwo: <MdOutlinePendingActions size={22} />,
  },
  {
    id: 5,
    key: 'transactions',
    item: 'Transactions',
    path: '/vendor/transactions',
    icon: <MdOutlinePendingActions size={22} />,
    iconTwo: <MdOutlinePendingActions size={22} />,
  },
  {
    id: 6,
    key: 'payments',
    item: 'Payment History',
    path: '/vendor/payments',
    icon: <MdPayments size={22} />,
    iconTwo: <MdPayments size={22} />,
  },
  {
    id: 7,
    key: 'profile',
    item: 'Profile',
    path: '/vendor/profile',
    icon: <Profile size={22} variant='Bold' />,
    iconTwo: <Profile size={22} variant='Bold' />,
  },
];

// {
//   id: 7,
//   key: 'Payments',
//   item: 'Payments',
//   path: '/admin/payments',
//   icon: <MdPayments size={22} />,
//   iconTwo: <MdPayments size={22} />,
// },
