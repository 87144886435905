import {useNavigate} from 'react-router-dom';
import styles from '../../assets/styles/modals/ConfirmModal.module.css';
interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAction?: () => void;
  label?: string;
  bgColor?: string;
  activeLabel?: string;
}
const ConfirmDeactivate = ({
  setIsOpen,
  onAction: deleHandler,
  bgColor,
  label,
  activeLabel,
}: IModal) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.confirmDeleteBG}
        onClick={() => {
          navigate(-1);
          // setIsOpen(true);
        }}
      />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            Are you sure you want to {activeLabel} this account?
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}>
                Cancel
              </button>
              <button
                style={{background: bgColor}}
                className={styles.deactivateBtn}
                onClick={() => {
                  deleHandler && deleHandler();
                  setIsOpen(false);
                }}>
                {label}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDeactivate;
