import {Eye, EyeSlash} from 'iconsax-react';
import {numberFormat} from '../Helpers';
import {cardImg} from '../../assets/images';
import {useState} from 'react';
import {RiSendPlaneFill} from 'react-icons/ri';

interface IVendorCard {
  onOpenWithdraw: () => void;
  profile: any;
}
const VendorCard = ({onOpenWithdraw, profile}: IVendorCard) => {
  const [isOpenWithdrawWallet, setIsOpenWithdrawWallet] = useState(false);
  const [isSalesHideBalance, setIsSalesHideBalance] = useState(false);
  const [isWithdrawHideBalance, setIsWithdrawHideBalance] = useState(false);
  // console.log(profile?.vendor_wallet, 'ss');

  return (
    <>
      {!isOpenWithdrawWallet ? (
        <div className='bg-white w-full rounded-2xl px-10  justify-center items-start h-[288px]'>
          <div className='flex flex-row justify-between items-start w-full'>
            {/* Details */}
            <div className='flex flex-col gap-10 py-10'>
              <p className='text-gray-400 font-poppinsMedium text-lg'>
                Sales Wallet Balance
              </p>
              {isSalesHideBalance ? (
                <p className='text-black font-poppinsSemibold text-4xl'>
                  **********
                </p>
              ) : (
                <p className='text-primary font-poppinsSemibold text-4xl inline-flex'>
                  NGN
                  <span className='text-black pl-2  font-poppinsSemibold'>
                    {numberFormat(profile?.vendor_wallet?.totalSales)}
                  </span>
                </p>
              )}
            </div>
            {/* Image */}
            <div className='sm:w-[168px] sm:h-[156px] h-20 w-20'>
              <img
                src={cardImg}
                alt='cardcoin'
                className='w-full h-full sm:object-cover object-contain'
              />
            </div>
          </div>
          <div className='sm:flex flex-row justify-between items-center w-full pt-4 hidden'>
            <div className='text-gray-400 font-poppinsRegular flex flex-row items-center gap-4'>
              <p className='font-poppinsRegular'>Hide Sales Balance</p>
              {isSalesHideBalance ? (
                <div
                  onClick={() => {
                    setIsSalesHideBalance(false);
                  }}
                  className='cursor-pointer'>
                  <Eye size={20} color='black' />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsSalesHideBalance(true);
                  }}
                  className='cursor-pointer'>
                  <EyeSlash size={20} color='black' />
                </div>
              )}
            </div>
            <div
              onClick={() => {
                setIsOpenWithdrawWallet(true);
              }}>
              <p className='text-primary underline hover:cursor-pointer font-poppinsMedium'>
                View Withdrawal Balance
              </p>
            </div>
          </div>
          {/* Mobile View */}
          <div className='flex flex-col gap-4 sm:hidden w-full'>
            <div className='flex flex-row justify-between items-center w-full pt-4 text-gray-400 font-poppinsRegular'>
              <p className='font-poppinsRegular'>Hide Sales Balance</p>
              {isSalesHideBalance ? (
                <div
                  onClick={() => {
                    setIsSalesHideBalance(false);
                  }}
                  className='cursor-pointer'>
                  <Eye size={20} color='black' />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsSalesHideBalance(true);
                  }}
                  className='cursor-pointer'>
                  <EyeSlash size={20} color='black' />
                </div>
              )}
            </div>
            <div
              onClick={() => {
                setIsOpenWithdrawWallet(true);
              }}>
              <p className='text-primary underline hover:cursor-pointer font-poppinsMedium'>
                View Withdrawal Balance
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className='bg-black w-full rounded-2xl px-10  justify-center items-start h-[288px]'>
          {/* Withdrawal balance */}
          <div className='flex flex-row justify-between items-start w-full'>
            {/* Details */}
            <div className='flex flex-col gap-10 py-10'>
              <p className='text-white font-poppinsMedium text-lg sm:block hidden'>
                Withdrawal Wallet Balance
              </p>
              <p className='text-white font-poppinsMedium text-lg block sm:hidden'>
                Withdrawal Balance
              </p>
              <div className='sm:flex flex-row gap-6 items-center hidden'>
                {isWithdrawHideBalance ? (
                  <div className='pt-2'>
                    <p className='text-white font-poppinsSemibold text-2xl'>
                      ************
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className='text-primary font-poppinsSemibold text-4xl inline-flex'>
                      NGN
                      <span className='text-white pl-2  font-poppinsSemibold'>
                        {numberFormat(
                          profile?.vendor_wallet?.withdrawableBalance
                        )}
                      </span>
                    </p>
                  </div>
                )}

                {isWithdrawHideBalance ? (
                  <div
                    onClick={() => {
                      setIsWithdrawHideBalance(false);
                    }}
                    className='cursor-pointer'>
                    <Eye size={24} color='white' />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setIsWithdrawHideBalance(true);
                    }}
                    className='cursor-pointer'>
                    <EyeSlash size={24} color='white' />
                  </div>
                )}
                {/* Mobile */}
              </div>
              <div className='flex flex-row gap-6 items-center sm:hidden'>
                {isWithdrawHideBalance ? (
                  <div className='pt-2'>
                    <p className='text-white font-poppinsSemibold text-2xl'>
                      ************
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className='text-primary font-poppinsSemibold text-4xl inline-flex'>
                      NGN
                      <span className='text-white pl-2  font-poppinsSemibold'>
                        {numberFormat(
                          profile?.vendor_wallet?.withdrawableBalance
                        )}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* Image */}
            <div className='sm:w-[168px] sm:h-[156px] h-20 w-20'>
              <img
                src={cardImg}
                alt='cardcoin'
                className='w-full h-full sm:object-cover object-contain'
              />
            </div>
          </div>
          <div className='sm:flex flex-row justify-between items-center w-full pt-4 hidden'>
            <div
              onClick={onOpenWithdraw}
              className='bg-white p-3 rounded-md text-primary font-poppinsRegular flex flex-row items-center gap-3 cursor-pointer'>
              <p className='font-poppinsRegular'>Run Ads</p>
              <RiSendPlaneFill size={20} />
            </div>
            <div
              onClick={() => {
                setIsOpenWithdrawWallet(false);
              }}>
              <p className='text-primary underline hover:cursor-pointer font-poppinsMedium'>
                View Sales Wallet Balance
              </p>
            </div>
          </div>
          {/* mobile */}
          <div className='flex-col gap-4 flex  sm:hidden'>
            <div className='flex flex-row justify-between items-center w-full sm:hidden'>
              <div
                onClick={onOpenWithdraw}
                className='bg-white p-3 rounded-md text-primary font-poppinsRegular flex flex-row items-center gap-3 cursor-pointer'>
                <p className='font-poppinsRegular'>Run Ads</p>
                <RiSendPlaneFill size={20} />
              </div>
              {isWithdrawHideBalance ? (
                <div
                  onClick={() => {
                    setIsWithdrawHideBalance(false);
                  }}
                  className='cursor-pointer'>
                  <Eye size={24} color='white' />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsWithdrawHideBalance(true);
                  }}
                  className='cursor-pointer'>
                  <EyeSlash size={24} color='white' />
                </div>
              )}
            </div>

            <div
              onClick={() => {
                setIsOpenWithdrawWallet(false);
              }}>
              <p className='text-primary underline hover:cursor-pointer font-poppinsMedium'>
                View Sales Wallet Balance
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VendorCard;
